import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, throwError, from, of } from 'rxjs';
import { switchMap, catchError, tap, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { StorageService } from '../storage/storage.service';
import { UtilService } from '../util/util.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userData: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  constructor(
    private http: HttpClient,
    private api: ApiService,
    private storage: StorageService,
    private util: UtilService,
    private translate: TranslateService
  ) {
    this.initializeUser();
  }

  private initializeUser() {
    this.storage.get('user').then(user => {
      if (user) {
        this.userData.next(user);
      } else {
        this.userData.next(null);
      }
    });
  }

  login(data: any) {
    const headers = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Accept', 'application/json')
    };
    this.storage.remove(this.api.TOKEN_KEY);
    return this.http.post<any>(`${this.api.endpoint}/auth/login`, data, headers).pipe(
      switchMap((res: any) => {
        if (res.access_token) {
          this.storage.set(this.api.TOKEN_KEY, res.access_token);
          this.util.consolelog('token', res.access_token);
          return of({ sucess: true });
        } else {
          return of(res);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => new Error('Usuário ou senha inválidos'));
      })
    );
  }

  registerUser(data: any) {
    const headers = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Accept', 'application/json')
    };
    this.storage.remove(this.api.TOKEN_KEY);
    return this.http.post<any>(`${this.api.endpoint}/auth/register`, data, headers).pipe(
      switchMap((res: any) => {
        if (res.access_token) {
          this.storage.set(this.api.TOKEN_KEY, res.access_token);
          this.util.consolelog('token', res.access_token);
          return of({ sucess: true });
        } else {
          return of(res);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => new Error(this.translate.instant('INTRO.LOGIN_FAIL')));
      })
    );
  }

  socialLoginWithAuth0(user: any, provider: string) {
    const headers = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Accept', 'application/json')
    };

    // Chamada para a API Laravel /auth/social
    return this.http.post<any>(`${this.api.endpoint}/auth/social`, {
      email: user.email,
      name: user.name,
      provider: provider,
      provider_id: user.sub
    }, headers).pipe(
      switchMap((res: any) => {
        if (res.access_token) {
          this.storage.set(this.api.TOKEN_KEY, res.access_token); // Armazena o token do Laravel
          this.util.consolelog('token', res.access_token);
          this.storage.set('user', res.user); // Armazena os dados do usuário
          this.userData.next(res.user); // Atualiza o estado do usuário
          return of({ success: true });
        } else {
          return of(res);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => new Error(this.translate.instant('INTRO.LOGIN_FAIL')));
      })
    );
  }


  logout() {
    this.storage.clear();
    this.userData.next(null);
  }
}
